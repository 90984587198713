import React, { useState, useEffect } from 'react';
import Main from "../../assets/images/main.png"
import Main1 from "../../assets/images/main (1).png"
import Main2 from "../../assets/images/main2.png"
import Main3 from "../../assets/images/main3.png"
import Arrow from "../../assets/images/arrow.png"

function Features() {
    const [showNewDiv, setShowNewDiv] = useState(false);
    const [showNewDiv1, setShowNewDiv1] = useState(false);
    const [showNewDiv2, setShowNewDiv2] = useState(false);
    const [showNewDiv3, setShowNewDiv3] = useState(false);
    return (

        <div className="container">
            <div class="row feature-card">
                {!showNewDiv ? (
                    <div class="col-md-11 feature-wrap left-border my-5">
                        <div class="row ">
                            <div class="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
                                <div class="mb-0 feature-head">Brain freezeee on
                                    ideas?
                                </div>
                                <p class="card-text w-100">Try our personalized script engine & create content that screams ‘YOU’!
                                </p>
                                <a onClick={() => setShowNewDiv(true)} class="stretched-link">Know More </a>
                            </div>
                            <div class="col-auto pt-5 feature-img">
                                <img src={Main} alt="Main" className='img-fluid pe-lg-5' width="350" height="auto" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="col-md-11 feature-wrap-pink left-border my-5">
                        <div class="row ">

                            <div class="col-4 pt-5 feature-img px-4">
                                <img src={Main} alt="Main" className='img-fluid' width="300" height="auto" />

                            </div>
                           
                            <div class="col-1 p-0 pt-5 feature-img d-flex align-items-start h-100vh">
                                <img src={Arrow} alt="Arrow" className='img-fluid arrow-img' width="150" height="150" />

                            </div>
                            <div class="col p-lg-5 p-4 d-flex position-static justify-content-center w-100">


                                <div>
                                    <p class="card-text">Capture your unique voice with ease! Just pick the genre, set the tone, choose the duration, and tweak the settings to fit your vibe- our AI will do the heavy lifting.


                                    </p>
                                    <p class="card-text">

                                        Whether it’s a fun promo or a heartfelt message, the engine shapes every line to match your style and engage your audience.
                                    </p>
                                    <a onClick={() => setShowNewDiv(false)} class="stretched-link">Know Less </a>
                                </div>

                            </div>
                        
                        </div>
                    </div>
                )}
{!showNewDiv1 ? (
                     <div class="col-md-11 feature-wrap right-border mb-5">
                     <div class="row ">
                         <div class="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
                             <div class="mb-0 feature-head">Need a pre-made awesome script?
                             </div>
                             <p class="card-text">We've got a library full of them for you to claim it!
                             </p>
                             <a onClick={() => setShowNewDiv1(true)} class="stretched-link">Know More </a>
                         </div>
                         <div class="col-auto feature-img">
                             <img src={Main1} alt="Main" className='img-fluid pe-lg-5' width="350" height="auto" />
                         </div>
                     </div>
                 </div>
                ) : (
                    <div class="col-md-11 feature-wrap-pink left-border my-5">
                        <div class="row ">

                            <div class="col-4 px-3 feature-img">
                                <img src={Main1} alt="Main" className='img-fluid' width="300" height="auto" />

                            </div>
                            <div class="col-1 pt-5 p-0 feature-img">
                                <img src={Arrow} alt="Arrow" className='img-fluid arrow-img' width="150" height="150" />

                            </div>
                            <div class="col p-lg-5 p-4 d-flex position-static justify-content-center w-100">

                                <div>
                                    <p class="card-text">Dive into the FlickVid library, for scripts that are ready to roll whenever you are!
                                    </p>
                                    <p class="card-text">

                                    Packed with ready-to-claim, pre-written scripts, each script is crafted by our AI that covers a range of genres, tones, and styles to fit whatever you’re creating. Just pick one, make it yours, and you’re all set.
                                    </p>
                                    <a onClick={() => setShowNewDiv1(false)} class="stretched-link">Know Less </a>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
               {!showNewDiv2 ? (
                     <div class="col-md-11 feature-wrap left-border mb-5">
                     <div class="row ">
                         <div class="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
                             <div class="mb-0 feature-head">Content creation taking forever?
 
                             </div>
                             <p class="card-text">Get detailed shot breakdowns to make top quality content!
                             </p>
                             <a onClick={() => setShowNewDiv2(true)} class="stretched-link">Know More </a>
                         </div>
                         <div class="col-auto feature-img">
                             <img src={Main3} alt="Main" className='img-fluid pe-lg-5' width="350" height="auto" />
                         </div>
                     </div>
                 </div>
                ) : (
                    <div class="col-md-11 feature-wrap-pink left-border my-5">
                        <div class="row ">

                            <div class="col-4 px-3 feature-img">
                                <img src={Main3} alt="Main" className='img-fluid' width="300" height="auto" />

                            </div>
                            <div class="col-1 pt-5 p-0 feature-img">
                                <img src={Arrow} alt="Arrow" className='img-fluid arrow-img' width="150" height="150" />

                            </div>
                            <div class="col p-lg-5 p-4 d-flex position-static justify-content-center w-100">


                                <div>
                                    <p class="card-text">Save time stressing over shot setups- FlickVid gives you step-by-step visuals so you know exactly what to capture, every time. 

                                    </p>
                                    <p class="card-text">
                                    For new creators, it’s the perfect roadmap.
For pros, it’s a fast pass to refining shots and nailing every detail. 
                                    </p>
                                    <a onClick={() => setShowNewDiv2(false)} class="stretched-link">Know Less </a>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
              
                  {!showNewDiv3 ? (
                     <div class="col-md-11 feature-wrap right-border mb-">
                     <div class="row ">
                         <div class="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
                             <div class="mb-0 feature-head">Clueless about shooting cinematic angles?
 
 
 
                             </div>
                             <p class="card-text">Get the perfect angles with our content sensitive camera!
                             </p>
                             <a  onClick={() => setShowNewDiv3(true)} class="stretched-link">Know More </a>
                         </div>
                         <div class="col-auto pb-5 feature-img">
                             <img src={Main2} alt="Main" className='img-fluid pe-lg-5' width="350" height="auto" />
                         </div>
                     </div>
                 </div>
                ) : (
                    <div class="col-md-11 feature-wrap-pink left-border my-5">
                        <div class="row ">

                            <div class="col-4 px-3 feature-img">
                                <img src={Main2} alt="Main" className='img-fluid' width="300" height="auto" />

                            </div>
                            <div class="col-1 pt-5 p-0 feature-img">
                                <img src={Arrow} alt="Arrow" className='img-fluid arrow-img' width="150" height="150" />

                            </div>
                            <div class="col p-lg-5 p-4 d-flex position-static justify-content-center w-100">


                                <div>
                                    <p class="card-text">No more struggling with awkward shots or missed moments- this camera is built to make creators shine.  

                                    </p>
                                    <p class="card-text">
With the ability to zoom in up to XYZ percent, you’ll capture every detail with precision, giving your content that competitive edge of premium quality.
                                    </p>
                                    <a onClick={() => setShowNewDiv3(false)} class="stretched-link">Know Less </a>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
               
                <div className="col-12 text-center">
                    <div className='all-in-one-place'>
                        All in one place!
                    </div>
                    <div className='text-center mb-5'>
                        <div className='download-app-btn'>
                            Try Now
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;