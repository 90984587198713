import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'

const DeleteAccountWeb = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (!password || !consentChecked) {
      // Show error
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/api/deleteUserAccount', {
        functionName: 'deleteUserAccount',
        password,
        username: localStorage.getItem('email')
      });

      if (response.data.success === "1") {
        // Handle successful deletion
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error('Delete account error:', error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
  
      <div className='gradient-wrap'>
        <Header />


      </div>
    <div className="container text-white mb-5">
      <div className="max-w-3xl mx-auto py-12 px-4">
        {/* Main Content */}
        <div className="space-y-8">
          <h1 className="text-3xl font-bold text-center mb-5">
            Delete Your Account
          </h1>

          {/* Warning Card */}
          <div className="bg-gradient-to-r from-red-500 to-red-800 p-[1px] rounded-lg">
            <div className="bg-gray-900 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-red-500 mb-4">
                Warning: This action cannot be undone
              </h2>
              
              <div className="space-y-4">
                <p className="text-gray-300">
                  Before proceeding, please understand that deleting your account will:
                </p>
                
                <ul className="list-disc pl-5 space-y-2 text-gray-300">
                  <li>Permanently delete all your projects and generated scripts</li>
                  <li>Remove all your saved preferences and settings</li>
                  <li>Cancel any active subscriptions</li>
                  <li>Delete all associated data and cannot be recovered</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Delete Button */}
          <div className="text-center">
            <button
              onClick={() => setIsModalOpen(true)}
             className="submit"
            >
              Delete My Account
            </button>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          //   <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full">
          //     <h2 className="text-xl font-semibold mb-4">
          //       Confirm Account Deletion
          //     </h2>

          //     <div className="space-y-4">
          //       <div>
          //         <label className="block text-sm font-medium text-gray-300 mb-1">
          //           Enter your password to confirm
          //         </label>
          //         <input
          //           type="password"
          //           value={password}
          //           onChange={(e) => setPassword(e.target.value)}
          //           className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
          //           placeholder="Password"
          //         />
          //       </div>

          //       <div className="flex items-center">
          //         <input
          //           type="checkbox"
          //           id="consent"
          //           checked={consentChecked}
          //           onChange={(e) => setConsentChecked(e.target.checked)}
          //           className="rounded bg-gray-700 border-gray-600 text-red-600 focus:ring-red-500"
          //         />
          //         <label htmlFor="consent" className="ml-2 text-sm text-gray-300">
          //           I understand that this action cannot be undone and all my data will be permanently deleted
          //         </label>
          //       </div>

          //       <div className="flex space-x-3">
          //         <button
          //           onClick={handleDeleteAccount}
          //           disabled={loading}
          //           className="flex-1 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg font-semibold transition-colors disabled:opacity-50"
          //         >
          //           {loading ? (
          //             <span className="flex items-center justify-center">
          //               <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
          //                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
          //                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          //               </svg>
          //               Deleting...
          //             </span>
          //           ) : (
          //             'Delete Account'
          //           )}
          //         </button>
                  
          //         <button
          //           onClick={() => setIsModalOpen(false)}
          //           className="flex-1 bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg font-semibold transition-colors"
          //         >
          //           Cancel
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // </div>
       
            <div className="modal-overlay">
              <div className="modal-container">
                <h2 className="modal-heading">Confirm Account Deletion</h2>
          
                <div className="space-y-6">
                  <div className="modal-input-container">
                    <label className="modal-label">Enter your password to confirm</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="modal-input"
                      placeholder="Password"
                    />
                  </div>
          
                  <div className="modal-checkbox-container">
                    <input
                      type="checkbox"
                      id="consent"
                      checked={consentChecked}
                      onChange={(e) => setConsentChecked(e.target.checked)}
                      className="modal-checkbox"
                    />
                    <label htmlFor="consent" className="modal-checkbox-label">
                      I understand that this action cannot be undone and all my data will be permanently deleted.
                    </label>
                  </div>
          
                  <div className="modal-buttons-container">
                    <button
                      onClick={handleDeleteAccount}
                      disabled={loading || !consentChecked}
                      className="modal-delete-button"
                    >
                      {loading ? (
                        <span className="modal-spinner">
                          <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                          </svg>
                          Deleting...
                        </span>
                      ) : (
                        'Delete Account'
                      )}
                    </button>
          
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="modal-cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
         
          
        )}
      </div>
    </div>
    <FooterBanner />
    <Footer />
    </div>
  );
};

export default DeleteAccountWeb;