import React from 'react'
import LogoImg from "../assets/images/logo.png"
import Instagram from "../assets/images/Instagram.png"
import Facebook from "../assets/images/facebook.png"
import Twitter from "../assets/images/Twitter.png"
import Youtube from "../assets/images/youtube.png"
import Linkdn from "../assets/images/linkdn.png"

function Footer() {
    
    return (
        <div class="container">
        <footer className='mt-lg-5 mt-3'>
            <div className='text-center'>
              <a href="/">              
              <img src={LogoImg} alt="Logo" className='img-fluid footer-logo' width="140" height="auto" />
              </a>
            </div>
          <ul class="nav justify-content-center pb-3 ">
            <li class="nav-item"><a href="/about" class="nav-link px-4">About Us</a></li>
            {/* <li class="nav-item"><a href="#" class="nav-link px-4">Media </a></li> */}
            <li class="nav-item"><a href="/contact" class="nav-link px-4">Contact Us</a></li>
          </ul>
          <ul class="nav justify-content-center pb-1">
          <li class="nav-item social-icons"><a href="https://www.instagram.com/weareflickvid/" class="nav-link px-2 text-muted">
            <img src={Instagram} alt="Instagram" className='img-fluid' width="40" height="40"/>
            </a></li>
            <li class="nav-item social-icons"><a href="https://www.facebook.com/share/161DaWP1N3/" class="nav-link px-2 text-muted">
            <img src={Facebook} alt="Instagram" className='img-fluid' width="40" height="40"/>
            </a></li>
            {/* <li class="nav-item social-icons"><a href="#" class="nav-link px-2 text-muted">
            <img src={Twitter} alt="Instagram" className='img-fluid' width="40" height="40"/>
            </a></li>
            <li class="nav-item social-icons"><a href="#" class="nav-link px-2 text-muted">
            <img src={Youtube} alt="Instagram" className='img-fluid' width="40" height="40"/>
            </a></li> */}
            <li class="nav-item social-icons"><a href="https://www.linkedin.com/company/flickvid/" class="nav-link px-2 text-muted">
            <img src={Linkdn} alt="Instagram" className='img-fluid' width="40" height="40"/>
            </a></li>
          </ul>
          <div class="d-flex justify-content-between py-4 mt-4 footer-border-top">
      <p className='mb-0 copyright-footer'>Copyright © 2024 FlickVid</p>
      <ul class="list-unstyled d-flex">
        {/* <li class="ms-3">
        Legal Disclaimer                  
           </li> */}
           <li class="ms-3">
            <a href="/privacy">
            Privacy Policy </a>   
           </li>
           <li class="ms-3">
            <a href="/terms-and-condition">
            Terms and Conditions</a>
           </li>
      </ul>
    </div>
        </footer>
      </div>
    )
}

export default Footer;